import { Link } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';

const SidebarSupport = (props) => {
    const { user } = useUser();
    const Title = ({ title }) => {
        return <>
            <p className='pt-sans-bold w-full pb-1 mb-2 border-b border-[#000] text-[#000]'>{title}</p>
        </>
    }

    return (
        <>
            <div className='h-full flex flex-col justify-between overflow-auto hideScrollbar'>
                <div className="relative">
                    <button className='px-4 py-1 text-sm border border-cf--primary text-cf--primary bg-white min-w-[90px] rounded-sm transition-all hover:text-white pt-sans-bold hover:bg-cf--primary absolute end-0 top-[40px]'>Edit</button>
                    <Title title={'Account'} />
                    <p className='text-cf--secondary text-sm mb-1'>Username:</p>
                    <p className='text-base mb-1'>{user?.name}</p>
                    <p className='mb-2'></p>
                    <p className='text-cf--secondary text-sm mb-1'>Email:</p>
                    <p className='text-base mb-1'>{user?.email}</p>
                    <p className='mb-2'></p>
{/*                     <p className='text-cf--secondary text-sm mb-1'>API Token:</p>
                    <p className='text-base mb-1'>asdadnojno321n3o1no23n12on3</p>
                    <p className='mb-2'></p> */}
                </div>
                <div className="relative">
                    <button className='px-4 py-1 text-sm border border-cf--primary text-cf--primary bg-white min-w-[90px] rounded-sm transition-all hover:text-white pt-sans-bold hover:bg-cf--primary absolute end-0 top-[40px]'>Payment</button>
                    <Title title={'Balance'} />
                    <p className='text-cf--secondary text-sm mb-1'>Balance:</p>
                    <p className='text-base mb-1'>$ 10</p>
                    <p className='mb-2'></p>
                    <p className='text-cf--secondary text-sm mb-1'>CarFax Price:</p>
                    <p className='text-base mb-1'>$ 1</p>
                    <p className='mb-2'></p>
                    <p className='text-cf--secondary text-sm mb-1'>Autocheck Price:</p>
                    <p className='text-base mb-1'>$ 1</p>
                    <p className='mb-2'></p>
                </div>
                <div className="relative">
                    <Title title={'Examples'} />
                    <div className='mb-1 flex items-center justify-between'>
                        <p className='text-base mb-1'>CarFax Report</p>
                        <button className='px-4 py-1 text-sm border border-cf--primary text-cf--primary bg-white min-w-[90px] rounded-sm transition-all hover:text-white pt-sans-bold hover:bg-cf--primary'>View</button>
                    </div>
                    <div className='flex items-center justify-between'>
                        <p className='text-base mb-1'>Autocheck Report</p>
                        <button className='px-4 py-1 text-sm border border-cf--primary text-cf--primary bg-white min-w-[90px] rounded-sm transition-all hover:text-white pt-sans-bold hover:bg-cf--primary'>View</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SidebarSupport;