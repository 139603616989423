import Title from "../components/Title";
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from "react-router-dom";

const Support = () => {
    return <>
        <div className="h-screen overflow-hidden">
            <Header />
            <div className="height-100vh-100px overflow-auto">
                <div className="container mx-auto flex items-start flex-row gap-4">
                    <div className="h-full w-full p-4">
                        <Title title={`Support`} />

                        <div class="bg-cf--off-white p-4 w-full max-w-[400px] rounded-sm">
                            <p class="text-lg mb-3">Contacts</p>
                           {/*  <p className="">Telegram</p>
                            <Link className="inline-block text-cf--primary underline text-sm mb-3" target="_blank" to="https://t.me/auto_retorts">@auto_retorts</Link>
                       */}      <p className="">E-mail</p>
                            <Link className="inline-block text-cf--primary underline text-sm mb-3" to="mailto:check_car_VIN@proton.me">check_car_VIN@proton.me</Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </>
}

export default Support;