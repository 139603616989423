import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuLeft from './MenuLeft';
import { Link } from 'react-router-dom';
import { MenuRounded } from '@mui/icons-material';
import MenuRight from './MenuRight';
import Logo from "../components/Logo";
const Header = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const toggleDrawer = (open) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setIsDrawerOpen(open);
    };

    return (
        <>
            <div className="bg-cf--light-grey h-[60px] px-2">
                <div className="container mx-auto h-full">
                    <div className='text-lg flex items-center justify-between h-full'>
                        <div className='flex items-center gap-6'>
                            <Link className='pt-sans-bold' to={`/dashboard`}><Logo small={true} /></Link>
                            <div className='hidden md:inline'>
                                <MenuLeft set="horizontal" />
                            </div>
                        </div>
                        <div>
                            <div className='hidden md:inline'>
                                <MenuRight set="horizontal" />
                            </div>
                            <div className='block md:hidden'>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    onClick={toggleDrawer(true)}
                                ><MenuRounded /></IconButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Drawer
                anchor="left"
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
            >
                <div
                    className='min-w-[300px] p-4'
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <MenuLeft />
                    <MenuRight />
                </div>
            </Drawer>
        </>
    );
}

export default Header;