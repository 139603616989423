import { Link } from "react-router-dom";

const AuthLayout = (props) => {
    return <>
        <div className="p-6 flex md:flex-row flex-col items-center justify-center h-screen">
            <div className="md:min-w-[500px] mb-6">
                {props.children}
            </div>
            <div className="text-lg">
                <p className="mb-3"><strong>Carfax Price : </strong> PLN 17</p>
                <p className="mb-3"><strong>Autocheck Price : </strong> PLN 17</p>
                <p className="mb-3"><strong>Carfax Example : </strong> PSN23567NI53 <Link className="text-cf--primary pt-sans-bold transition-all underline hover:text-cf--secondary" target="_blank" to={'/carfax-example'}>View</Link></p>
                <p className="mb-3"><strong>Autocheck Example : </strong> PSN23567NI53 <Link className="text-cf--primary pt-sans-bold transition-all underline hover:text-cf--secondary" target="_blank" to={'/autocheck-example'}>View</Link></p>
            </div>
        </div>
    </>
}

export default AuthLayout;