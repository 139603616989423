import { Link } from 'react-router-dom';
import Payment from './Payment';
import { useState } from 'react';
import UserEdit from './UserEdit';
import { useUser } from '../contexts/UserContext';
const Sidebar = (props) => {
    const { updateUser } = useUser();
    const { user } = useUser();
    
    const Title = ({ title }) => {
        return <>
            <p className='pt-sans-bold w-full pb-1 mb-2 border-b border-[#000] text-[#000]'>{title}</p>
        </>
    }

    const [name, setName] = useState(user?.name);

    const [openPM, setOpenPM] = useState(false);
    const handleOpenPM = () => setOpenPM(true);
    const handleClosePM = () => setOpenPM(false);

    const [openUE, setOpenUE] = useState(false);
    const handleOpenUE = () => setOpenUE(true);
    const handleCloseUE = (value) => {
        console.log(value);
        if (value) {
            setName(value);
            updateUser(value);
        }
        setOpenUE(false)
    };

    return (
        <>
            <div className='h-full flex flex-row md:flex-col justify-between overflow-auto hideScrollbar gap-5'>
                <div className="relative">
                    <button onClick={handleOpenUE} className='hidden md:block px-4 py-1 text-sm border border-cf--primary text-cf--primary bg-white min-w-[90px] rounded-sm transition-all hover:text-white pt-sans-bold hover:bg-cf--primary absolute end-0 top-[40px]'>Edit</button>
                    <Title title={'Account'} />
                    <p className='text-cf--secondary text-sm mb-1'>Username:</p>
                    <p className='text-base mb-1'>{user?.name}</p>
                    <p className='mb-2'></p>
                    <p className='text-cf--secondary text-sm mb-1'>Email:</p>
                    <p className='text-base mb-1'>{user?.email}</p>
                    <button onClick={handleOpenUE} className='block md:hidden px-4 py-1 text-sm border border-cf--primary text-cf--primary bg-white min-w-[90px] rounded-sm transition-all hover:text-white pt-sans-bold hover:bg-cf--primary'>Edit</button>
                    <p className='mb-2'></p>
                </div>
                <div className="relative">
                    <button onClick={handleOpenPM} className='hidden md:block px-4 py-1 text-sm border border-cf--primary text-cf--primary bg-white min-w-[90px] rounded-sm transition-all hover:text-white pt-sans-bold hover:bg-cf--primary absolute end-0 top-[40px]'>Payment</button>
                    <Title title={'Balance'} />
                    <p className='text-cf--secondary text-sm mb-1'>Balance:</p>
                    <p className='text-base mb-1'>PLN {user?.balance}</p>
                    <p className='mb-2'></p>
                    <p className='text-cf--secondary text-sm mb-1'>CarFax Price:</p>
                    <p className='text-base mb-1'>PLN 17</p>
                    <p className='mb-2'></p>
                    <p className='text-cf--secondary text-sm mb-1'>Autocheck Price:</p>
                    <p className='text-base mb-1'>PLN 17</p>
                    <button onClick={handleOpenPM} className='block md:hidden px-4 py-1 text-sm border border-cf--primary text-cf--primary bg-white min-w-[90px] rounded-sm transition-all hover:text-white pt-sans-bold hover:bg-cf--primary'>Payment</button>
                    <p className='mb-2'></p>
                </div>
                <div className="relative">
                    <Title title={'Examples'} />
                    <div className='mb-1 flex flex-col md:flex-row items-left md:items-center justify-start md:justify-between'>
                        <p className='text-base mb-1'>CarFax Report</p>
                        <Link to={'/carfax-example'} rel="noreferrer" target="_blank" className='px-4 py-1 text-center text-sm border border-cf--primary text-cf--primary bg-white max-w-fit md:max-w-auto md:min-w-[90px] rounded-sm transition-all hover:text-white pt-sans-bold hover:bg-cf--primary'>View</Link>
                    </div>
                    <div className='flex flex-col md:flex-row items-left md:items-center justify-start md:justify-between'>
                        <p className='text-base mb-1'>Autocheck Report</p>
                        <Link to={'/autocheck-example'} rel="noreferrer" target="_blank" className='px-4 py-1 text-center text-sm border border-cf--primary text-cf--primary bg-white max-w-fit md:max-w-auto md:min-w-[90px] rounded-sm transition-all hover:text-white pt-sans-bold hover:bg-cf--primary'>View</Link>
                    </div>
                </div>
            </div>
            <Payment
                open={openPM}
                setOpen={setOpenPM}
                handleOpen={handleOpenPM}
                handleClose={handleClosePM}
            />
            <UserEdit
                open={openUE}
                data={name}
                setOpen={setOpenUE}
                handleOpen={handleOpenUE}
                handleClose={handleCloseUE}
            />
        </>
    );
}

export default Sidebar;