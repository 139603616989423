import React from "react";
import { useNavigate } from "react-router-dom";
import Title from "../components/Title";
import DashboardLayout from "../layouts/Dashboard";

const SuccessPage = () => {
  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <div className="flex flex-col items-center justify-center pt-24">
        <Title title="Success" className="text-cf--primary" />
        <p className="mt-4 text-lg">
          Your account has been created successfully.
        </p>
        <p className="mt-2 text-sm text-gray-600">
          You can check your report in the dashboard.
        </p>
        <button
          className="mt-6 px-4 py-2 bg-cf--primary text-white rounded hover:bg-cf--secondary transition-all"
          onClick={() => navigate("/dashboard")}
        >
          Go Back to Dashboard
        </button>
      </div>
    </DashboardLayout>
  );
};

export default SuccessPage;
