import React from "react";
import { useNavigate } from "react-router-dom";
import Title from "../components/Title";
import DashboardLayout from "../layouts/Dashboard";

const CancelPage = () => {
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <div className="flex flex-col items-center justify-center pt-24">
        <Title title="Cancelled" className="text-red-600" />
        <p className="mt-4 text-lg">Your payment was cancelled.</p>
        <p className="mt-2 text-sm text-gray-600">
          You can try again or return to the dashboard.
        </p>
        <button
          className="mt-6 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-all"
          onClick={() => navigate("/dashboard")}
        >
          Go Back to Dashboard
        </button>
      </div>
    </DashboardLayout>
  );
};

export default CancelPage;
