import Title from "../components/Title";
import DashboardLayout from "../layouts/Dashboard";
import Box from "../components/Box";
import BoxLeft from "../components/BoxLeft";
import BoxRight from "../components/BoxRight";
import React, { useEffect, useState } from 'react';
import SortableTable from "../components/SortableTable";
import axios from "axios";
import { API_URL } from "../constants/config";
// import axios from 'axios';

const Payments = () => {

    const [formData, setFormData] = useState({
        date: '',
    });

    useEffect(() => {
        fetchData();
    }, [])

    function fetchData() {
        setTableData([])
        let token = localStorage.getItem('token');
        axios.get(`${API_URL}/get-payments${formData.date === "" ? "" : "/" + formData.date}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(function (response) {
                console.log(response);
                setTableData(response.data.data)
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    function handleCheck() {
        console.log("~ handleCheck")
        console.log(formData, "~ handleCheck search")
        fetchData()
    }

    function reset() {
        setFormData({
            date: '',
        })
    }

    const fields = [
        { id: 'created_at', label: 'Date' },
        { id: 'amount', label: 'Total, $' },
        { id: 'status', label: 'Description' },
    ];

    const [tableData, setTableData] = useState([]);

    return <>
        <DashboardLayout>
            <Title title={`Payments`} />
            <Box>
                <BoxLeft>
                    <div className="flex flex-col items-start gap-2">
                        <input
                            type="date"
                            onChange={(e) => handleChange(e)}
                            placeholder="date"
                            name="date"
                            value={formData.date}
                            className="mb-2 rounded-[5px] w-full border border-cf--light-grey bg-cf--off-white p-2"
                        />
                        <div className="w-full gap-2 flex items-center justify-between">
                            <button className="border pt-sans-bold tracking-wide text-cf--primary bg-cf--off-white rounded-[5px] px-3 py-2 transition-all hover:bg-cf--light-grey" onClick={reset}>Reset</button>
                            <button className="border pt-sans-bold tracking-wide text-white bg-cf--primary rounded-[5px] px-3 py-2 transition-all hover:bg-cf--secondary" onClick={handleCheck}>Check</button>
                        </div>
                    </div>
                </BoxLeft>
                <BoxRight>
                    <SortableTable data={tableData} fields={fields} />
                </BoxRight>
            </Box>
        </DashboardLayout>
    </>
}

export default Payments;