import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API_URL } from "../constants/config";
import axios from 'axios';

const ViewReport = () => {

    const { id } = useParams();
    const [reportHtml, setReportHtml] = useState("")

    useEffect(() => {
        let token = localStorage.getItem('token');
        axios.get(`${API_URL}/get-report-data/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(function (response) {
                console.log(response);
                setReportHtml(response.data.data.report_html)
            })
            .catch(function (error) {
                console.log(error);
            })
    }, [id])

    return (
        <>
        {
            reportHtml === "" 
            ? "Loading..." 
            :  <div dangerouslySetInnerHTML={{ __html: reportHtml }} />
        }
        </>
    );
}

export default ViewReport;
