
const AutoCheckExample = (props) => {
    return <>
        <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
            <iframe
                src="https://api.allreports.tools/page-autocheck-example/"
                style={{ width: '100%', height: '100%', border: 'none' }}
                title="Autocheck Example"
            />
        </div>
    </>
}

export default AutoCheckExample;