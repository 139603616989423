import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { API_URL } from "../constants/config";
import axios from 'axios';
import Logo from '../components/Logo';
import { useUser } from '../contexts/UserContext';
const ProtectedRoute = ({ element: Component, ...rest }) => {
    const token = localStorage.getItem('token');
    const [user, setUser] = useState(false);
    const [loading, setLoading] = useState(true);
    const { updateUser } = useUser();
    
    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        try {
            const response = await axios.post(`${API_URL}/get/user`, null, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response?.data?.data) {
                localStorage.setItem("user", JSON.stringify(response.data.data));
                updateUser(response.data.data);
                setUser(true);
            } else {
                localStorage.removeItem("token")
                localStorage.removeItem("user")
                setUser(false);
            }
        } catch (error) {
            console.log(error);
            localStorage.removeItem("token")
            localStorage.removeItem("user")
            setUser(false);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <>
            <div className='preloader-main w-full h-screen bg-white'>
                <div className='preloader-div flex items-center justify-center flex-col h-full'>
                    <Logo animate={true} />
                </div>
            </div>
        </>;
    }

    return user ? <Component {...rest} /> : <Navigate to="/signin" replace />;
};

export default ProtectedRoute;