import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import axios from 'axios';
import { API_URL } from "../constants/config";
import {errorAlert, successAlert} from '../utils/toast';
import { useUser } from '../contexts/UserContext';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #ddd',
    boxShadow: 24,
    borderRadius: "4px",
};

const UserEdit = (props) => {
    const open = props.open
    const setOpen = props.setOpen
    const handleOpen = props.handleOpen
    const handleClose = props.handleClose
    const { user } = useUser();
    const data = props.data;
    
    const [formData, setFormData] = useState({
        name: data
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const token = localStorage.getItem("token")
    
        const data = {
            name: formData.name,
            user_id: user.id
        }
        axios.post(`${API_URL}/update-data`, data,{
            headers: {
                'Authorization': `${token}`
            }        
        })
        .then(function (response) {
            console.log(response);
            if( response.status === 200){
                successAlert('Update SuccessFully');
                handleClose(response.data.data.user)
            }
        })
        .catch(function (error) {
            console.log(error);
            errorAlert(error.response.data.message);
        })
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <div className='flex items-center justify-between p-4 border-b'>
                        <p className='font-bold text-lg'>Update profile</p>
                        <button className='text-black' onClick={handleClose}>
                            <CloseIcon />
                        </button>
                    </div>
                    <div className='p-4 border-b'>
                        <form>
                            <label>Name</label>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className='w-full border rounded-md px-2 py-1 mb-4'
                            />
                            <button className='w-[100px] rounded-md px-3 py-1 bg-cf--primary transition-all hover:bg-cf--secondary text-white' onClick={handleSubmit}>Update</button>
                        </form>
                    </div>
                    <div className='text-end p-4'>
                        <button className='px-3 py-1 rounded-md bg-cf--primary text-white transition-all hover:bg-cf--secondary' onClick={handleClose}>
                            Close
                        </button>
                    </div>
                </Box>
            </Modal>
        </>
    );
}

export default UserEdit;