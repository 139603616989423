import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { useState } from "react";
import { API_URL } from "../constants/config";
import { errorAlert } from "../utils/toast";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #ddd",
  boxShadow: 24,
  borderRadius: "4px",
};

// Spinner component
const Spinner = () => (
  <svg
    className="animate-spin h-5 w-5 mr-3 text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <circle className="opacity-25" cx="12" cy="12" r="10" strokeWidth="4" />
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 0114.32-4.32A5 5 0 009 16.5A5 5 0 004 12z"
    />
  </svg>
);

const Payment = (props) => {
  const open = props.open;
  const handleClose = props.handleClose;

  const [kwota, setKwota] = useState(17);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handlePaymentClick = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      const data = {
        amount: kwota,
        currency: "PLN",
      };
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${API_URL}/stripe/create-session`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { url } = response.data.data;

      setTimeout(() => {
        setIsSubmitting(false);
        // Redirect to url
        window.location.href = url;
      }, 2000);
    } catch (error) {
      console.error("Error", error);
      errorAlert("Payment initialization error from server!");
      setIsSubmitting(false);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <div className="flex items-center justify-between p-4 border-b">
          <p className="font-bold text-lg">Choose Payment System</p>
          <button className="text-black" onClick={handleClose}>
            <CloseIcon />
          </button>
        </div>
        <div className="p-4 border-b">
          <form id="order">
            <p className="font-bold mb-0">Carfax: 17 PLN ($4)</p>
            <p className="font-bold mb-4">Autocheck: 17 PLN ($4)</p>

            <label>Amount</label>
            <input
              required
              className="w-full border rounded-md px-2 py-1 mb-4"
              name="KWOTA"
              value={kwota}
              type="number"
              onChange={(e) => setKwota(e.target.value)}
            />
            <div className="flex flex-col gap-4">
              {/* Stripe Card Payment button */}
              <button
                className="w-full rounded-md px-4 py-2 bg-cf--primary text-white font-extrabold transition-all hover:bg-cf--secondary"
                disabled={isSubmitting}
                onClick={() => handlePaymentClick()}
              >
                {isSubmitting ? (
                  <div className="flex items-center justify-center">
                    <Spinner />
                    <span>Processing...</span>
                  </div>
                ) : (
                  "Card or Blik"
                )}
              </button>
            </div>
          </form>
        </div>
        <div className="text-end p-4">
          <button
            className="px-3 py-1 rounded-md bg-cf--primary text-white transition-all hover:bg-cf--secondary"
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default Payment;
