import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthLayout from "../layouts/Auth";
import axios from 'axios';
import { API_URL } from "../constants/config";
import {errorAlert, successAlert} from '../utils/toast';
import Logo from "../components/Logo";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({});
    const [email, setEmail] = useState('');
    const [showForm, setShowForm] = useState(false);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleChangeEmail = (e) => {
        const { name, value } = e.target;
        setEmail(value);
    };
    const handleForgotPassword = (e) => {
        e.preventDefault();
        console.log("~ handleForgotPassword")
        if(!showForm){
            resetPasswordEmail();
        } else{
            changePassword();
        }
        
    }

    const resetPasswordEmail = () => {
        const data = {
            email: email
        }
        axios.post(`${API_URL}/forgot-password`, data)
        .then(function (response) {
            console.log(response);
            if( response.status === 200){
                successAlert('Email Sent');
                setShowForm(true);
            }
        })
        .catch(function (error) {
            console.log(error.response.data.message);
            errorAlert(error.response.data.message);
        })
    }
    const changePassword = () => {
        console.log(formData);
        const data = formData;
         data['email'] = email
        
        axios.post(`${API_URL}/change-password`, data)
        .then(function (response) {
            console.log(response);
            if( response.status === 200){
                successAlert('Password Changed Successfully')
                navigate("/signin")
            }
        })
        .catch(function (error) {
            console.log(error);
            errorAlert(error.response.data.message);
        })
    }
    
    return <>
        <AuthLayout>
            <Logo />
            <p className="pt-sans-bold text-3xl text-center mb-6">Forgot password</p>
            <form className="w-full md:max-w-[400px] mx-auto">
               {!showForm ? <input
                    onChange={(e) => { handleChangeEmail(e) }}
                    placeholder="Email address"
                    name="email"
                    type="email"
                    className="w-full mb-3 bg-white rounded-md px-4 py-2 border border-cf--secondary text-cf--secondary"
                /> :
                <>
                <input
                    onChange={(e) => { handleChange(e) }}
                    placeholder="New Password"
                    name="password"
                    type="password"
                    className="w-full mb-3 bg-white rounded-md px-4 py-2 border border-cf--secondary text-cf--secondary"
                />
                <input
                    onChange={(e) => { handleChange(e) }}
                    placeholder="Code from Email"
                    name="code"
                    type="code"
                    className="w-full mb-3 bg-white rounded-md px-4 py-2 border border-cf--secondary text-cf--secondary"
                />
                </>
            }
                <button className="mb-4 w-full pt-sans-bold tracking-wide text-white bg-cf--primary rounded-md text-lg px-6 py-3 transition-all hover:bg-cf--secondary" onClick={handleForgotPassword}>{showForm ? 'Change Password' : 'Send Email'}</button>
                <div className="flex items-center justify-center gap-4 pt-sans-regular">
                    <Link className="transition-all hover:text-cf--secondary font-bold text-cf--primary" to={`/signin`}>Sign In</Link>
                    <div className="w-[1px] h-[20px] bg-cf--light-grey"></div>
                    <Link className="transition-all hover:text-cf--secondary font-bold text-cf--primary" to={`/signup`}>Sign Up</Link>
                </div>
            </form>
        </AuthLayout>
    </>
}

export default ForgotPassword;