import Title from "../components/Title";
import DashboardLayout from "../layouts/Dashboard";
import Box from "../components/Box";
import BoxLeft from "../components/BoxLeft";
import BoxRight from "../components/BoxRight";
import React, { useEffect, useState } from 'react';
import SortableTable from "../components/SortableTable";
import axios from 'axios';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { API_URL } from "../constants/config";

const History = () => {

    const [formData, setFormData] = useState({
        vin: '',
        date: '',
        type: '',
    });

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = () => {
        let token = localStorage.getItem('token');
        setTableData([])
        axios.get(`${API_URL}/get-reports`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            }, params: formData
        })
            .then(function (response) {
                console.log(response);
                setTableData(response.data.data)
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDateRange = (e, selectedDates) => {
        setFormData({ ...formData, date: selectedDates });
    };

    function handleCheck() {
        console.log("~ handleCheck")
        console.log(formData, "~ handleCheck search")
        fetchData()
    }

    function reset() {
        setFormData({
            vin: '',
            date: '',
            type: '',
        })
    }

    const fields = [
        {
            id: 'vin',
            label: 'Vin'
        },
        {
            id: 'created_at',
            label: 'Date'
        },
        {
            id: 'price',
            label: 'Price, $'
        },
        {
            id: 'type',
            label: 'Type'
        },
        {
            id: 'view',
            label: 'View Report'
        },
        {
            id: 'pdf',
            label: 'Pdf'
        },
    ];

    const [tableData, setTableData] = useState([]);

    return <>
        <DashboardLayout>
            <Title title={`History`} />
            <Box>
                <BoxLeft>
                    <div className="flex flex-col items-start gap-2">
                        <input
                            type="text"
                            onChange={(e) => handleChange(e)}
                            placeholder="Vin code"
                            name="vin"
                            value={formData.vin}
                            className="mb-2 rounded-[5px] w-full border border-cf--light-grey bg-cf--off-white p-2"
                        />
                        <Flatpickr
                            value={formData.date}
                            options={{
                                mode: "range",
                                dateFormat: "Y-m-d",
                            }}
                            onChange={(e, selectedDates) => handleDateRange(e, selectedDates)}
                            className="mb-2 rounded-[5px] w-full border border-cf--light-grey bg-cf--off-white p-2"
                            placeholder="Date range"
                        />
                        <select
                            onChange={(e) => handleChange(e)}
                            name="type"
                            defaultValue={""}
                            value={formData.type}
                            className="mb-2 rounded-[5px] w-full border border-cf--light-grey bg-cf--off-white p-2"
                        >
                            <option value="">Select report type</option>
                            <option value="carfax">CarFax</option>
                            <option value="autocheck">Autocheck</option>
                        </select>
                        <div className="w-full gap-2 flex items-center justify-end md:justify-between">
                            <button className="border pt-sans-bold tracking-wide text-cf--primary bg-cf--off-white rounded-[5px] px-3 py-2 transition-all hover:bg-cf--light-grey" onClick={reset}>Reset</button>
                            <button className="border pt-sans-bold tracking-wide text-white bg-cf--primary rounded-[5px] px-3 py-2 transition-all hover:bg-cf--secondary" onClick={handleCheck}>Check</button>
                        </div>
                    </div>
                </BoxLeft>
                <BoxRight>
                    <SortableTable data={tableData} fields={fields} />
                </BoxRight>
            </Box>
        </DashboardLayout>
    </>
}

export default History;