import { Link } from "react-router-dom";
import Logo from "../components/Logo";
const PublicPageLayout = (props) => {
    return <>
        <div className="flex md:flex-row flex-col items-center justify-center">
            <Logo />
        </div>
        <div className="mb-2">
            {props.children}
        </div>
    </>
}

export default PublicPageLayout;