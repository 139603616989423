
const CarfaxExample = (props) => {
    return <>
        <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
            <iframe
                src="https://api.allreports.tools/page-carfax-example/"
                style={{ width: '100%', height: '100%', border: 'none' }}
                title="Carfax"
            />
        </div>
    </>
}

export default CarfaxExample;