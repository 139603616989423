const Title = (props) => {
  return (
    <>
      <p className={`pt-sans-bold mb-4 text-lg ${props.className}`}>
        {props.title}
      </p>
    </>
  );
};

export default Title;
