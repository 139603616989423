import { Link } from 'react-router-dom';
const Footer = () => {

    return (
        <>
            <div className="relative z-10 bg-cf--light-grey h-[40px] p-2">
                <div className="container mx-auto h-full">
                    <div className="text-xs text-center leading-[24px] flex items-center justify-center gap-3">
                        <Link className='font-bold transition-all hover:underline' to={`/privacy-policy`} target="_blank">Privacy Policy</Link>
                        <div className='h-[25px] w-[1px] bg-slate-600'>

                        </div>
                        <Link className='font-bold transition-all hover:underline' to={`/terms-of-service`} target="_blank">Terms of Website Services</Link>

                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;