import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthLayout from "../layouts/Auth";
import axios from 'axios';
import { API_URL } from "../constants/config";
import { errorAlert, successAlert } from '../utils/toast';
import Logo from "../components/Logo";

const Signup = () => {
    const [formData, setFormData] = useState({});
    const [response, setResponse] = useState("");
    const [error, setError] = useState("");
    const [checked, setChecked] = useState(false);
    const navigate = useNavigate();
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

    };

    const setCheckBox = (e) => {
        setChecked(!checked);
    };

    const handleSignup = (e) => {
        e.preventDefault();
        setResponse("")
        setError("")
        if (formData.password !== formData.confirm_password) {
            errorAlert("Passwords do not match.")
        }else if (!checked){
            errorAlert("please accept the Terms of Website Use and Privacy Policy")
        }
        else {
            axios.post(`${API_URL}/register`, formData)
                .then(function (res) {
                    console.log(res);
                    successAlert(res.data.message);
                    navigate("/")
                })
                .catch(function (error) {
                    console.log(error);
                    errorAlert(error.response.data.message)
                })
        }
    }

    return <>
        <AuthLayout>
            <Logo />
            <p className="pt-sans-bold text-3xl text-center mb-2">Sign Up for your account</p>
            <p className="pt-sans-regular text-center mb-6">Already have an account? <Link className="transition-all hover:text-cf--secondary font-bold text-cf--primary" to={`/`}>Sign In</Link></p>
            <form className="w-full md:max-w-[400px] mx-auto">
                <input
                    onChange={(e) => { handleChange(e) }}
                    placeholder="Full name"
                    name="full_name"
                    type="text"
                    className="w-full mb-3 bg-white rounded-md px-4 py-2 border border-cf--secondary text-cf--secondary"
                />
                <input
                    onChange={(e) => { handleChange(e) }}
                    placeholder="Email address"
                    name="email"
                    type="email"
                    className="w-full mb-3 bg-white rounded-md px-4 py-2 border border-cf--secondary text-cf--secondary"
                />
                <input
                    onChange={(e) => { handleChange(e) }}
                    placeholder="Password"
                    name="password"
                    type="password"
                    className="w-full mb-3 bg-white rounded-md px-4 py-2 border border-cf--secondary text-cf--secondary"
                />
                <input
                    onChange={(e) => { handleChange(e) }}
                    placeholder="Confirm Password"
                    name="confirm_password"
                    type="password"
                    className="w-full mb-3 bg-white rounded-md px-4 py-2 border border-cf--secondary text-cf--secondary"
                />
                <label className="text-sm mb-3 block">
                    <input type="checkbox"
                        defaultChecked={checked}
                        className="me-2"
                        onChange={(e) => { setCheckBox(e) }}
                    />
                    Kindly Accept the
                        <Link className='font-bold mx-1 transition-all hover:underline' to={`/terms-of-service`} target="_blank">Terms of Website Use</Link>
                    and
                        <Link className='font-bold mx-1 transition-all hover:underline' to={`/privacy-policy`} target="_blank">Privacy Policy</Link>

                    
                </label>
                <button className="w-full pt-sans-bold tracking-wide text-white bg-cf--primary rounded-md text-lg px-6 py-3 transition-all hover:bg-cf--secondary" onClick={handleSignup}>Sign Up</button>
                {
                    response && response !== "" && <p className="my-4 text-green-700 text-xs text-center">{response}</p>
                }
                {
                    error && error !== "" && <p className="my-4 text-red-700 text-xs text-center">{error}</p>
                }
            </form>
        </AuthLayout>
    </>
}

export default Signup;