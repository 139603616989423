// src/contexts/UserContext.js
import React, { createContext, useState, useContext } from 'react';

// Create a Context for the user
const UserContext = createContext();

// Create a Provider component
const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Function to update user information
  const updateUser = (newUser) => {
    setUser(newUser);
  };

  return (
    <UserContext.Provider value={{ user, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the UserContext
const useUser = () => useContext(UserContext);

export { UserProvider, useUser };
