import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthLayout from "../layouts/Auth";
import axios from 'axios';
import { API_URL } from "../constants/config";
import Logo from "../components/Logo";
import {errorAlert, successAlert} from '../utils/toast';
const Signin = () => {
    const [formData, setFormData] = useState({});
    const navigate = useNavigate();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSignin = (e) => {
        e.preventDefault();
        axios.post(`${API_URL}/login`, formData)
            .then(function (response) {
                console.log(response);
                localStorage.setItem("token", response.data.data.token);
                localStorage.setItem("user", JSON.stringify(response.data.data.user));
                navigate("/dashboard")
            })
            .catch(function (error) {
                console.log(error);
                errorAlert(error.response.data?.message)
            })
    }

    return <>
        <AuthLayout>
         <Logo />
            <p className="pt-sans-bold text-3xl text-center mb-2">Sign In to your account</p>
            <p className="pt-sans-regular text-center mb-6">Don't have an account yet? <Link className="transition-all hover:text-cf--secondary font-bold text-cf--primary" to={`/signup`}>Sign Up</Link></p>
            <form className="w-full md:max-w-[400px] mx-auto">
                <input
                    onChange={(e) => { handleChange(e) }}
                    placeholder="Email address"
                    name="email"
                    type="email"
                    className="w-full mb-3 bg-white rounded-md px-4 py-2 border border-cf--secondary text-cf--secondary"
                />
                <input
                    onChange={(e) => { handleChange(e) }}
                    placeholder="Password"
                    name="password"
                    type="password"
                    className="w-full mb-3 bg-white rounded-md px-4 py-2 border border-cf--secondary text-cf--secondary"
                />
                <p className="text-end mb-3">
                    <Link className="transition-all hover:text-cf--secondary font-bold text-cf--primary" to={'/forgot-password'}>Forgot Password?</Link>
                </p>
                <button className="w-full pt-sans-bold tracking-wide text-white bg-cf--primary rounded-md text-lg px-6 py-3 transition-all hover:bg-cf--secondary" onClick={handleSignin}>Sign In</button>
            </form>
        </AuthLayout>
    </>
}

export default Signin;