import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthLayout from "../layouts/Auth";
import axios from 'axios';
import { API_URL } from "../constants/config";
import {errorAlert, successAlert} from '../utils/toast';
import { useParams } from 'react-router-dom';

const EmailActivation = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [firstTime, setFirstTime] = useState(true);
    const [showSuccess , setShowSuccess] = useState(false);    
    
    useEffect(() => {
        console.log(firstTime);
        if(id && firstTime){
        setFirstTime(false);
        axios.post(`${API_URL}/activate-account/${id}`)
            .then(function (response) {
                console.log(response);
                if(response.status === 200){
                    setShowSuccess(true);
                    successAlert('Email Verified Successfully')

                }
                
                
            })
            .catch(function (error) {
                console.log(error);
                errorAlert(error.response.data.message);
            })
        }
    }, [])
    
    return <>
        <AuthLayout>
            <p className="text-cf--primary pt-serif-regular-italic text-5xl text-center mb-4">Logo</p>
            <p className="pt-sans-bold text-3xl text-center mb-6">Email Verification</p>
            {showSuccess ? <p className="pt-sans-regular text-center mb-6">Your Account Has been Verified <Link className="transition-all hover:text-cf--secondary font-bold text-cf--primary" to={`/signin`}>Sign In</Link></p>
             : <p className="pt-sans-regular text-center mb-6">Verification In progress</p>
            }
            
        </AuthLayout>
    </>
}

export default EmailActivation;