import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
const MenuRight = (props) => {
    const navigate = useNavigate();
    const { user } = useUser();
    
    function logout() {
        console.log("logout")
        localStorage.removeItem("token")
        localStorage.removeItem("user")
        navigate("/signin")
    }

    return (
        <>
            <div className={`${props.set !== undefined && props.set === "horizontal" ? "flex-row" : "flex-col"} text-sm flex items-start md:items-center gap-2 pt-sans-regular`}>
                <span className='p-2'>{user?.name}</span>
                <span className='text-lg pt-sans-bold text-cf--primary p-2'>PLN {user?.balance}</span>
                <button onClick={logout} className='p-2'>Logout</button>
            </div>
        </>
    );
}

export default MenuRight;