import Footer from "../components/Footer";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import SidebarSupport from "../components/SidebarSupport";

const DashboardLayout = (props) => {

    return <>
        <div className="md:h-screen md:overflow-hidden">
            <Header />
            <div className="md:height-100vh-100px container mx-auto flex items-start flex-col md:flex-row gap-4">
                <div className="bg-cf--off-white md:h-full w-full md:w-1/4 p-2">
                    {
                        props.page !== undefined && props.page === "support"
                            ? <>
                                <SidebarSupport />
                            </>
                            : <Sidebar />
                    }
                </div>
                <div className="p-4 w-full h-full overflow-auto hideScrollbar">
                    {props.children}
                </div>
            </div>
            <Footer />
        </div>
    </>
}

export default DashboardLayout;