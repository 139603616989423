import { Link, useLocation } from 'react-router-dom';

const MenuLeft = (props) => {
    const location = useLocation();

    return (
        <>
            <div className={`${props.set !== undefined && props.set === "horizontal" ? "flex-row" : "flex-col"} text-sm flex items-start md:items-center gap-2 pt-sans-regular`}>
                <Link className={`${location.pathname === '/dashboard' ? "font-bold" : ""} p-2 transition-all`} to={`/dashboard`}>Dashboard</Link>
                <Link className={`${location.pathname === '/history' ? "font-bold" : ""} p-2 transition-all`} to={`/history`}>History</Link>
                <Link className={`${location.pathname === '/payments' ? "font-bold" : ""} p-2 transition-all`} to={`/payments`}>Payments</Link>
                <Link className={`${location.pathname === '/support' ? "font-bold" : ""} p-2 transition-all`} to={`/support`}>Support</Link>
            </div>
        </>
    );
}

export default MenuLeft;